import React from "react"
import { Link } from "gatsby"

const Layout = props => {
  const { title, children, location } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  let locationIndex
  switch (true) {
    case location?.pathname === "/another-24/":
      locationIndex = 1
      break
    case location?.pathname === "/free-form-select/":
      locationIndex = 2
      break
    case location?.pathname === "/lattice/":
      locationIndex = 3
      break
    default:
      locationIndex = 0
  }
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <li
                className={`nav-home ${
                  locationIndex == 0 ? "nav-current" : ""
                }`}
                role="menuitem"
              >
                <Link to={`/`}>Home</Link>
              </li>
              <li
                className={`nav-elements ${
                  locationIndex == 1 ? "nav-current" : ""
                }`}
                role="menuitem"
              >
                <Link to={`/another-24/`}>Another 24</Link>
              </li>
              <li
                className={`nav-elements ${
                  locationIndex == 2 ? "nav-current" : ""
                }`}
                role="menuitem"
              >
                <Link to={`/free-form-select/`}>free-form select</Link>
              </li>
              <li
                className={`nav-elements ${
                  locationIndex == 3 ? "nav-current" : ""
                }`}
                role="menuitem"
              >
                <Link to={`/lattice/`}>Lattice</Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {title}
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <Link
                href="https://warpcast.com/bchow"
                title="Farcaster"
                target="_blank"
                rel="noopener noreferrer"
              >
                Farcaster
              </Link>
              <Link
                href="https://twitter.com/bchow_art"
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </Link>
              <Link
                href="https://www.instagram.com/bchow_art/"
                title="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </Link>
              <Link
                href="https://github.com/bstchow"
                title="GitHub"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </Link>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>Brandon Chow</Link>
      </footer>
    </div>
  )
}

export default Layout
